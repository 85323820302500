<template>
  <div :class="{active: active}" class="col-grow-1 h-full flex justify-center items-center setup-container-erp">
    <!--    <div v-if="step === 'welcome'" style="width: 700px; height: 300px" class="text-center flex row items-start justify-start">
         <div class="text-left">
           <vue-typed-js :showCursor="false" :startDelay="2000" :typeSpeed="5" :strings="['Olá Tiago :)']">
             <div class="typing welcome-user"></div>
           </vue-typed-js>
           <vue-typed-js :showCursor="false" :startDelay="2300" :typeSpeed="5" :strings="['Precisamos atualizar o seu software para a versão mais recente.']">
             <div class="typing welcome-text m-t-lg font-16"></div>
           </vue-typed-js>
           <vue-typed-js :showCursor="false" :startDelay="3500" :typeSpeed="5" :strings="['Pedimos que aguarde um pouco...']">
             <div class="typing welcome-text m-t-xs font-16"></div>
           </vue-typed-js>
         </div>
        </div>-->
    <div v-if="step === 'progress'" style="width: 400px">
      <div ref="container" class="m-b-sm text-center textContainer"></div>
      <u-progress class="progress-sgrp m-r"
                  :percentage="progress"
                  color="positive" height="6px" style="border-radius: 5px; max-width: 200px; margin: auto"></u-progress>
    </div>
    <div v-if="step === 'success'">
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
    </div>
    <div v-if="step === 'fail'" style="max-width: 1000px; margin: auto">
      <i class="fas fa-cloud-hail" style="font-size: 50px"></i>
      <br><br>
      Houve uma falha ao tentar fazer a atualização. Entre em contato o mais breve possível.
      <br><br>
      Falha:
      <br><br>
      {{ falhaText }}
      <br><br>
      <u-btn class="app-btn m-t" :disable="loadingProfileUpdate" color="blue-grey-5" no-caps
             label="Ciente, fechar atualização" @click="pularCadastro"/>
    </div>
    <div class="profile" v-if="step === 'profile'">
      <div class="full-width">
        <div class="hello">
          <div>Olá <span class="capitalize">{{ nome }}</span>,</div>
          <div class="small m-t-xs">Precisamos que atualize o seu cadastro.</div>
        </div>

        <div class="m-t-lg">
          <user-form :user-id="$uloc.auth.session.user.id" @updated="atualizarCadastro" disable-success-saved-dialog />

<!--          <div class="m-t-xxl">
            <u-btn :loading="loadingProfileUpdate" class="app-btn" color="green" label="Atualizar e continuar"
                   @click="atualizarCadastro" no-caps/>
            <u-btn class="app-btn m-l" :disable="loadingProfileUpdate" flat no-caps
                   label="Não atualizar meus dados agora" @click="pularCadastro"/>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UProgress, UInput, LocalStorage} from "uloc-vue"
import {fileToBase64} from "@/utils/fileToBase64"
import {updateUserProfilePicture, upgrade} from "@/domain/usuario/services"
import {setup} from "@/domain/globalconfig/services"
import UserForm from "components/usuario/v2/components/UserForm.vue"

export default {
  name: "Initiator",
  provide: function () {
    return {
      userPage: this
    }
  },
  data() {
    return {
      active: false,
      step: 'progress',
      progress: 0,
      text: null,
      loadingPicture: false,
      loadingProfileUpdate: false,
      profile: {
        photo: this.$uloc.auth.session.user.photo || 'http://www.gravatar.com/avatar/xxx?s=256',
        name: this.$uloc.auth.session.user.name,
        email: this.$uloc.auth.session.user.email,
        phone: null,
      },
      falhaText: null
    }
  },
  computed: {
    nome() {
      const nome = String(this.$uloc.auth.session.user.name).split(' ')
      return nome[0]
    }
  },
  components: {
    UserForm,
    UProgress,
    //UInput
  },
  mounted() {
    setTimeout(() => {
      this.active = true
      this.startSetup()
    }, 1000)
  },
  methods: {
    startSetup() {
      setTimeout(() => {
        this.init()
      }, 1200)
    },
    init() {
      setTimeout(() => {
        try {
          setup()
              .then(() => {
                if (!this.$uloc.auth.logged) return
                if (!this.$uloc.auth.session.user.config) {
                  this.$uloc.auth.session.user.config = {
                    business: {
                      businessAppVersion: 0,
                      appVersion: 0
                    }
                  }
                }
                this.$uloc.auth.session.user.config.business.businessAppVersion = this.$uloc.auth.session.user.config.business['appVersion']
                this.$uloc.auth.session.user.config.userVersion = this.$uloc.auth.session.user.config.business['appVersion']
                LocalStorage.set('auth.user', this.$uloc.auth.session.user)
                // @TODO: <TIAGO> <URGENTE> - Criar metodo para recarregar as configurações do usuário e atualizar o session e storage. Tornar session reativo
              })
              .catch(error => {
                this.step = 'fail'
                this.falhaText = error.data
                if (error && error.data && error.data.title && String(error.data.title).toLowerCase() == 'unauthorized') {
                  setTimeout( () => {
                    this.$router.push('/logout')
                  }, 500)
                  setTimeout( () => {
                    this.$router.push('/login')
                  }, 600)
                }
                clearInterval(this.$interval)
              })
        } catch (e) {
          console.log(e)
          this.alertApiError(e)
        }
      }, 4000)
      this.$interval = setInterval(() => {
        if (this.progress >= 100) {
          clearInterval(this.$interval)
        }
        this.progress = this.progress + 1
        switch (this.progress) {
          case 2:
            this.changeText('Iniciando atualização e configuração do software para a versão 3.6')
            break
          case 30:
            this.changeText('Verificando preferências do usuário')
            break
          case 31:
            this.changeText('Analisando parâmetros da interface')
            break
          case 32:
            this.changeText('Verificando controle de ACL')
            break
          case 33:
            this.changeText('Buscando configurações globais')
            break
          case 35:
            this.changeText('Definindo configurações')
            break
          case 40:
            this.changeText('Configurando módulo financeiro')
            break
          case 50:
            this.changeText('Configurando módulo leilões')
            break
          case 60:
            this.changeText('Configurando CRM')
            break
          case 70:
            this.changeText('Configurando módulo marketing')
            break
          case 80:
            this.changeText('Configurando gestor processual')
            break
          case 90:
            this.changeText('Configurando módulo pessoas')
            break
          case 95:
            this.changeText('Finalizando atualização')
            break
          case 100:
            setTimeout(() => {
              this.step = 'success'
              setTimeout(() => {
                this.step = 'profile'
              }, 4000)
            }, 4000)
            break
        }
      }, 200)
    },
    changeText(t) {
      const div = document.createElement('div')
      div.classList.add('statusMessage')
      div.innerHTML = t
      const active = this.$refs.container && this.$refs.container.querySelector('.active')
      if (active) {
        active.classList.add('ended')
      }
      this.$refs.container.append(div)
      this.$nextTick(() => {
        setTimeout(() => {
          const ended = this.$refs.container.querySelector('.ended')
          if (ended) {
            this.$refs.container.removeChild(ended)
          }
          div.classList.add('active')
        }, 100)
      })
    },
    setFile(evt) {
      const file = evt.target.files[0]
      setTimeout(() => {
        fileToBase64(file)
            .then(response => {
              this.updateProfilePicture(response)
            })
            .catch(error => {
              alert('Ocorreu um erro na leitura do arquivo')
            })
      }, 50)
    },
    updateProfilePicture(data) {
      this.$uloc.loading.show()
      updateUserProfilePicture(this.$uloc.auth.session.user.id, {data, filename: 'foto'})
          .then(response => {
            this.$uloc.loading.hide()
            this.profilePicture = response.data.image
            let user = LocalStorage.get.item('auth.user')
            user.photo = response.data.image
            this.profile.photo = response.data.image
            LocalStorage.set('auth.user', user)
            this.$uloc.auth.session.user = user
            this.$uloc.auth.loginByStorage()
            // @TODO: session não está reativo, por isto não atualiza a foto. Verificar
          })
          .catch(error => {
            this.$uloc.loading.hide()
            alert('Ocorreu um erro ao alterar a foto de usuário')
            console.log(error)
          })
    },
    atualizarCadastro() {
      this.loadingProfileUpdate = true
      upgrade(this.$uloc.auth.session.user.id, this.profile)
          .then(response => {
            this.loadingProfileUpdate = false
            this.$uloc.auth.session.user.name = response.data.person.name
            this.$uloc.auth.session.user.email = response.data.email
            this.$uloc.auth.session.user.phone = response.data.phone
            this.pularCadastro()
          })
          .catch(error => {
            this.loadingProfileUpdate = false
            this.alertApiError(error)
          })
    },
    pularCadastro() {
      this.step = 'success'
      setTimeout(() => {
        this.finish()
      }, 3000)
    },
    finish() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="stylus">
.welcome-user {
  font-size 88px
}

.welcome-text {
  font-size 22px
}

@keyframes setupShowAnim {
  0% {
    opacity 0
  }
  100% {
    opacity 1
  }
}

@keyframes setupShowAnimOut {
  0% {
    opacity 1
  }
  100% {
    opacity 0
  }
}

.setup-container-erp {
  position fixed
  left 0
  right 0
  top 0
  height 100vh
  background-color #FFFFFF
  z-index 10000
  opacity 0
  transition all 1s ease

  &.active {
    opacity 1
  }

  * {
    -webkit-font-smoothing: subpixel-antialiased !important;
    -moz-osx-font-smoothing: subpixel-antialiased !important;
  }
  -webkit-font-smoothing: subpixel-antialiased !important;
  -moz-osx-font-smoothing: subpixel-antialiased !important;
  font-weight 400

  .textContainer {
    display flex
    height 30px
    justify-content center
    flex-direction row
    align-items flex-end
  }

  .statusMessage {
    animation: setupShowAnim 1s ease;
    transition margin 500ms ease
    margin-left -20px
    position absolute

    &.active {
      margin-left 0
    }

    &.ended {
      animation: setupShowAnimOut 1s ease
      margin-left 40px !important
    }
  }
}


.profile {
  position absolute
  top 100px
  left 0
  right 0
  max-width 1100px;
  min-width 800px
  height 100%
  background: transparent linear-gradient(180deg, #F8F8F8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 2px 2px 1px #FFFFFF, 0px 0px 12px #00000064;
  border-radius: 21px
  margin auto
  display flex
  justify-content space-between
  padding 80px
  animation: setupFromBottom 300ms ease;
  overflow auto

  .user-form-v2 {
    padding-bottom 100px
  }

  /*&:after {
    content ''
    position absolute
    bottom 0
    left -100px
    right -100px
    top 50%
    pointer-events none
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 50%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  }*/

  .hello {
    font-size 22px
    line-height 1.2
  }
}
</style>
